import { combineReducers } from 'redux';
import {
  SHOW_LOGIN_STATUS,
  SET_USET_INFO,
  SHOW_REGISTRY_STATUS,
  SHOW_RESET_PASSWORD_STATUS,
  SHOW_CHANGE_PASSWORD_STATUS,
  SHOW_REDEEM_CODE_STATUS,
  SHOW_SCAN_TO_PAY_STATUS,
  SET_READ_VIDEO_INFO,
  SET_USER_COURSE_LIST
} from './types';

let defaultUserInfo = {};
let defaultReadVideoInfo = {};
let defaultUserCourseList = [];
if (typeof window !== 'undefined') {
  defaultUserInfo = window.localStorage.userInfo;
  defaultUserInfo = defaultUserInfo ? JSON.parse(defaultUserInfo) : {};
  defaultReadVideoInfo = window.localStorage.readVideoInfo;
  defaultReadVideoInfo = defaultReadVideoInfo
    ? JSON.parse(defaultReadVideoInfo)
    : {};
  defaultUserCourseList = window.localStorage.userCourseList;
  defaultUserCourseList = defaultUserCourseList
    ? JSON.parse(defaultUserCourseList)
    : [];
}

const loginStatus = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_LOGIN_STATUS:
      return action.status;
    default:
      return state;
  }
};

const registryStatus = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_REGISTRY_STATUS:
      return action.status;
    default:
      return state;
  }
};

const resetPasswordStatus = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_RESET_PASSWORD_STATUS:
      return action.status;
    default:
      return state;
  }
};

const changePasswordStatus = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_CHANGE_PASSWORD_STATUS:
      return action.status;
    default:
      return state;
  }
};

const redeemCodeStatus = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_REDEEM_CODE_STATUS:
      return action.status;
    default:
      return state;
  }
};

const scanTopayStatus = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_SCAN_TO_PAY_STATUS:
      return action.status;
    default:
      return state;
  }
};

const userInfo = (state = defaultUserInfo, action) => {
  const { type } = action;
  switch (type) {
    case SET_USET_INFO:
      localStorage.userInfo = JSON.stringify(action.userInfo);
      return action.userInfo;
    default:
      return state;
  }
};

const readVideoInfo = (state = defaultReadVideoInfo, action) => {
  const { type } = action;
  switch (type) {
    case SET_READ_VIDEO_INFO:
      localStorage.readVideoInfo = JSON.stringify(action.readVideoInfo);
      return action.readVideoInfo;
    default:
      return state;
  }
};

const userCourseList = (state = defaultUserCourseList, action) => {
  const { type } = action;
  switch (type) {
    case SET_USER_COURSE_LIST:
      localStorage.userCourseList = JSON.stringify(action.userCourseList);
      return action.userCourseList;
    default:
      return state;
  }
};

export default combineReducers({
  loginStatus,
  registryStatus,
  resetPasswordStatus,
  changePasswordStatus,
  redeemCodeStatus,
  scanTopayStatus,
  userInfo,
  readVideoInfo,
  userCourseList
});
